import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index/index.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/tabbarPages/home/home.vue')
      },
      {
        path: '/introduction',
        name: 'Introduction',
        component: () => import(/* webpackChunkName: "home" */ '../views/tabbarPages/introduction/introduction.vue')
      },
      {
        path: '/merchants',
        name: 'Merchants',
        component: () => import(/* webpackChunkName: "home" */ '../views/tabbarPages/merchants/merchants.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "home" */ '../views/tabbarPages/contact/contact.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    store.commit('changeCurrentEquipment', 1)
  } else {
    store.commit('changeCurrentEquipment', 0)
  }
  next()
})

export default router
