import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentEquipment: 0 //  0为pC 1为移动
  },
  mutations: {
    changeCurrentEquipment (state, val) {
      if (state.currentEquipment !== val) {
        state.currentEquipment = val
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
